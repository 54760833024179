/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react"
import moment from "moment"
import axios from "axios"
// import ReactHtmlParser from "react-html-parser"
import ReactHtmlParser from "html-react-parser"
import { useForm } from "react-hook-form"
import { Spinner } from "react-bootstrap"
import Smile from "../../../images/smile.png"
import LogoWd from "../../../images/logo.png"
import { useSelector } from "react-redux"

const Banner = ({
  data: {
    blog,
    gqlData: { banner, contentSection, commentSection },
  },
}) => {
  const [isLoading, setLoader] = useState(false)
  const { language } = useSelector(state => state.global)
  const [postComments, setComment] = useState([])
  const [formValues, setFormvalue] = useState({
    author_name: "",
    author_email: "",
    content: "",
  })
  useEffect(() => {
    axios
      .get("https://dev.webduratech.com/wp-json/wp/v2/comments")
      .then(res => {
        if (res.data.length > 0) {
          const filteredComments = res.data.filter(
            comment => comment.post === blog.databaseId
          )
          setComment(filteredComments)
        }
      })
      .catch(err => console.log(err))
  }, [blog.databaseId])
  const categories = blog.categories.nodes.map(e => e.name).join(",")
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleInputChange = e => {
    e.preventDefault()
    const {
      target: { name, value },
    } = e
    setFormvalue({ ...formValues, [name]: value })
  }

  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  const formValidation = {
    author_name: {
      required: "First Name is Required",
    },
    author_email: {
      required: "Email is Required",
      pattern: {
        value: emailRegex,
        message:
          language === "sv" ? "Ogiltig e-postadress" : "Invalid email address",
      },
    },
    content: {
      required: "Comment cannot be blank",
    },
  }

  const onHandleSumbit = () => {
    setLoader(true)
    const updatedFormValues = { ...formValues, post: blog.databaseId }
    axios
      .post(
        "https://sweden.webduratech.com/wp-json/wp/v2/comments",
        updatedFormValues
      )
      .then(res => {
        setFormvalue({
          author_name: "",
          author_email: "",
          content: "",
        })
        setComment(prevComment => prevComment.concat(res.data))
      })
      .catch(err => console.log({ err }))
      .finally(() => {
        setLoader(false)
      })
  }

  return (
    <section className="blog_box">
      <div className="blog_bannner">
        <div
          className="blog_bannner_content"
          style={{
            backgroundImage: `url(${blog.single_blog.featuredImages?.coverImage?.sourceUrl})`,
          }}
        >
          <div className="container p-0">
            <div className="blog_detail_txt text-center">
              <h6>{categories}</h6>
              <h1 dangerouslySetInnerHTML={{ __html: blog.title }} />
              {/* <span>{moment(blog.date).format("DD MMM YYYY")}</span> */}
              <h3>{banner.buttonText}</h3>
              <ul className="p-0">
                <li>
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.webdura.tech/blogs/${blog.slug}&title=&summary=&source=`}
                    className="btn"
                    target="__blank"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${blog.title}&&url=https://www.webdura.tech/blogs/${blog.slug}`}
                    className="btn"
                    target="__blank"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://www.webdura.tech/blogs/${blog.slug}`}
                    className="btn"
                    target="__blank"
                  >
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:info@example.com?&subject=&body=https://www.webdura.tech/blogs/${blog.slug} `}
                    className="btn"
                    target="__blank"
                  >
                    <i className="fa fa-envelope" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container p-0">
          <div className="whiteset">
            <div>{ReactHtmlParser(blog.content)}</div>
            <div className="smileset text-center">
              <img alt="smile" src={Smile} />
              <h4>{contentSection.thanksLabel[language]}</h4>
            </div>
          </div>
          <div className="author_block">
            <div className="author_lft">
              <div className="logo_side">
                <img
                  // className="rounded-circle"
                  alt={blog.author.firstName}
                  src={LogoWd}
                />
              </div>
            </div>
            <div className="author_ryt">
              <h2>
                {blog.author.firstName}
                {blog.author.lastName}
              </h2>
              <p>{blog.author.description}</p>
            </div>
          </div>
          <div className="author_block comment_box d-block">
            <h2 className="blogsub_head">{commentSection.title[language]}</h2>
            {postComments.length > 0
              ? postComments.map(comment => (
                  <>
                    <div className="comment_blk">
                      <div className="cmt_lft">
                        <img
                          src={comment.author_avatar_urls[96]}
                          alt="comments"
                        />
                      </div>
                      <div className="cmt_ryt">
                        <div className="txt_reply">
                          <h3>{comment.author_name}</h3>
                        </div>
                        <h4>
                          {moment(comment.date_gmt, "YYYY-MM-DD[T]HH:mm:ss")
                            .utc()
                            .local()
                            .fromNow()}
                        </h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: comment.content.rendered,
                          }}
                        />
                      </div>
                    </div>
                  </>
                ))
              : null}
          </div>

          <div className="post_bolk">
            <div className="post_comment">
              <h2 className="blogsub_head">
                {commentSection.form.title[language]}
              </h2>
              <div className="post_form">
                <form onSubmit={handleSubmit(onHandleSumbit)}>
                  <div className="post_input">
                    {commentSection.form.fields.slice(0, 2).map(field => (
                      <div className="post_one">
                        <label htmlFor={field.label[language].toLowerCase()}>
                          {field.label[language]}
                        </label>
                        <input
                          type={field.type}
                          name={field.name}
                          value={formValues[field.name]}
                          id={field.label[language].toLowerCase()}
                          {...register(`${field.name}`, {
                            ...formValidation[field.name],
                            required: field.errorMessage[language],
                          })}
                          onChange={e => handleInputChange(e)}
                        />
                        <span
                          style={{
                            color: "red",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          {errors[field.name] && errors[field.name].message}
                        </span>
                      </div>
                    ))}
                  </div>
                  {commentSection.form.fields.slice(2).map(field => (
                    <div className="post_input d-block">
                      <label htmlFor="comment">{field.label[language]}</label>
                      <textarea
                        type={field.type}
                        rows="3"
                        id="comment"
                        value={formValues[field.name]}
                        name={field.name}
                        {...register(`${field.name}`, {
                          ...formValidation[field.name],
                          required: field.errorMessage[language],
                        })}
                        onChange={e => handleInputChange(e)}
                      />
                      <span
                        style={{
                          color: "red",
                          fontSize: "14px",
                          textAlign: "left",
                        }}
                      >
                        {errors[field.name] && errors[field.name].message}
                      </span>
                    </div>
                  ))}
                  <button type="submit" value="Submit" className="webbtn m-0">
                    {isLoading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      <>
                        {commentSection.buttonText[language]}
                        <i className="arrow" />
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
