/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react"
import moment from "moment"
import { Link } from "gatsby"
import { useSelector } from "react-redux"

let isotope = ""
const BlogsList = () => {
  const { language } = useSelector(state => state.global)

  const { nodes } = useSelector(({ blogs }) => blogs.posts)

 
  const updatedNodes = nodes.filter(
    node => node.tags.nodes.filter(e => e.name === language).length>0
  )


  const [activeFilter, setFilters] = useState("all")
  
  useEffect(() => {
    const Isotope = require("isotope-layout")
    isotope = new Isotope(".grid", {
      itemSelector: ".all",
      layoutMode: "fitRows",
      masonry: { rowHeight: 210 },
    })
    setTimeout(() => {
      isotope.arrange()
    }, 1000)
  }, [language])

  const filterItem = filterValue => {
    isotope.shuffle()
    isotope.arrange({ filter: `.${filterValue}` })
    setFilters(filterValue)
  }
  let dd = []
  updatedNodes.forEach(blog => {
    blog.categories.nodes.forEach(e => dd.push(e.name))
  })
  dd = [...new Set(dd)]

 


  return (
    <section>
      <div className="container">
        <div className="portfolio blog">
          <div className="filters text-center">
            <ul>
              <li
                className={activeFilter === "all" ? "active" : ""}
                onClick={() => filterItem("all")}
              >
                All
              </li>
              {dd.map(filter => (
                <li
                  className={
                    activeFilter === filter.replace(/ /g, "").toLowerCase()
                      ? "active"
                      : ""
                  }
                  key={filter.replace(/ /g, "").toLowerCase()}
                  onClick={() =>
                    filterItem(filter.replace(/ /g, "").toLowerCase())
                  }
                >
                  {filter}
                </li>
              ))}
            </ul>
          </div>
          <div className="filters-content">
            <div
              className="row grid ml-0 mr-0"
              style={{
                position: "relative",
                minHeight: language === "en" ? "auto" : "auto",
              }}
            >
              {updatedNodes.map(blog => (
                <div
                  className={`filter_box all ${blog.categories.nodes
                    .map(e => e.name.replace(/ /g, "").toLowerCase())
                    .join(" ")}`}
                  key={blog.title}
                >
                  <div className="grid-item">
                    <Link className="blogbox" to={`/blogs/${blog.slug}`}>
                      <img
                        src={
                          blog.single_blog.featuredImages.thumbnailImage
                            ?.sourceUrl
                        }
                        className="img-fluid"
                        alt="blog-img"
                      />
                      <div className="blogbox_inner">
                        <h6>{blog.categories.nodes[0].name}</h6>
                        <h3 dangerouslySetInnerHTML={{ __html: blog.title }} />
                        {/* <span>{moment(blog.date).format("DD MMM yyyy")}</span> */}
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogsList
