import React from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { EnglishBlogs } from "../../queries/blogs"
import { SwedishBlogs } from "../../queries/swedishBlogs"
import { STACK_CONTEXTS } from "../Helpers/constants"

import { ReactNativeBlogs } from "../../queries/StackBlogs/en/reactnative"
import { ReactJsBlogs } from "../../queries/StackBlogs/en/reactjs"
import { NodeJsBlogs } from "../../queries/StackBlogs/en/nodejs"
import { PythonBlogs } from "../../queries/StackBlogs/en/python"
import { PhpBlogs } from "../../queries/StackBlogs/en/php"
import { MlBlogs } from "../../queries/StackBlogs/en/ml"
import { MlBlogsSW } from "../../queries/StackBlogs/sw/ml"
import { PythonBlogsSW } from "../../queries/StackBlogs/sw/python"
import { PhpBlogsSW } from "../../queries/StackBlogs/sw/php"
import { NodeJsBlogsSW } from "../../queries/StackBlogs/sw/nodejs"
import { ReactNativeBlogsSW } from "../../queries/StackBlogs/sw/reactnative"
import { ReactJsBlogsSW } from "../../queries/StackBlogs/sw/reactjs"

const Blog = ({ context }) => {
  const language = useSelector(state => state.global.language)

  const getBlogs = () => {
    switch (language) {
      case "en":
        switch (context) {
          case STACK_CONTEXTS[0]:
            return ReactJsBlogs()
          case STACK_CONTEXTS[1]:
            return ReactNativeBlogs()
          case STACK_CONTEXTS[2]:
            return NodeJsBlogs()
          case STACK_CONTEXTS[3]:
            return PhpBlogs()
          case STACK_CONTEXTS[4]:
            return PythonBlogs()
          case STACK_CONTEXTS[5]:
            return MlBlogs()
          default:
            return EnglishBlogs()
        }
      case "sv":
        switch (context) {
          case STACK_CONTEXTS[0]:
            return ReactJsBlogsSW()
          case STACK_CONTEXTS[1]:
            return ReactNativeBlogsSW()
          case STACK_CONTEXTS[2]:
            return NodeJsBlogsSW()
          case STACK_CONTEXTS[3]:
            return PhpBlogsSW()
          case STACK_CONTEXTS[4]:
            return PythonBlogsSW()
          case STACK_CONTEXTS[5]:
            return MlBlogsSW()
          default:
            return SwedishBlogs()
        }
      default:
        return EnglishBlogs()
    }
  }

  const {
    page: { homepage_contents },
    posts,
  } = getBlogs()
  const blogPageData = homepage_contents?.blog

  return (
    <section className="blog_news">
      <div className="container">
        <h2 className="brdrhtwo">{blogPageData?.text?.[language]}</h2>
        <h3 className="blog_newshead">{blogPageData?.title?.[language]}</h3>
        <div className="rowblog">
          {posts?.nodes?.map(item => (
            <Link
              to={`/blogs/${item.slug}`}
              className="blogbox"
              key={item.slug}
            >
              <LazyLoadImage
                effect="blur"
                src={item.single_blog.featuredImages.thumbnailImage.sourceUrl}
                alt="img"
                className="img-fluid"
              />
              <div className="blogbox_inner">
                <h6>{item?.categories?.nodes[0]?.name}</h6>
                <h3 dangerouslySetInnerHTML={{ __html: item?.title }} />
                {/* <span>{moment(item?.date).format("DD MMM yyyy")}</span> */}
              </div>
            </Link>
          ))}
        </div>
        <Link className="blog_btn" to="/blogs">
          {blogPageData?.blogbuttontext[language]}
          <i className="arrow" />
        </Link>
      </div>
    </section>
  )
}

export default Blog
